<template>

<div class="tingfujiDetail">
    <div class="group">
        <h1 class="title">停复机信息</h1>
        <ul>
            <li>
                <div>类型</div>
                <div>
                    <span v-if="entity.type === 1">复机</span>
                    <span v-else-if="entity.type === 2">停机</span>
                </div>
            </li>
            <li>
                <div>状态</div>
                <div>
                    <span v-if="entity.status === 1">成功</span>
                    <span v-else-if="entity.status === 2">失败</span>
                </div>
            </li>
            <li>
                <div>虚拟号</div>
                <div>
                    <span v-if="entity.invNo">{{entity.invNo}}</span>
                    <span v-else>-</span>
                </div>
            </li>
            <li>
                <div>操作时间</div>
                <div>{{entity.createTime}}</div>
            </li>
        </ul>
    </div>

    <div class="group">
        <h1 class="title">执行原因</h1>
        <div class="text">{{entity.operationRemark}}</div>
    </div>

    <div class="group">
        <h1 class="title">详细描述</h1>
        <div class="text">{{entity.remark}}</div>
    </div>
</div>

</template>

<script type="text/ecmascript-6">

import {viewDetail} from "@/api/cardManagement/cardBillDetail.js";

export default {
    data(){
        return {
            entity:{}
        }
    },
    mounted(){
        this._viewDetail()
    },
    methods:{
        _viewDetail(){
            let params = this.$route.query
            viewDetail(params).then((res)=>{
                this.entity = res.entity
            })
        }
    }
}
</script>

<style>
ul{list-style: none;}
.tingfujiDetail .title{line-height:50px;border-bottom:1px solid #ececec;padding:0 15px;}
.tingfujiDetail .group{margin-bottom:20px; }
.tingfujiDetail .group ul{display: flex;justify-content: space-between;line-height:50px;border-bottom:1px solid #ececec;padding:0 20px;}
.tingfujiDetail .group ul div{display: inline-block;}
.tingfujiDetail .group ul div:nth-child(2n+1){width:90px}
.tingfujiDetail .text{min-height:60px;border-bottom:1px solid #ececec;padding:0 15px;line-height: 50px;}
.tingfujiDetail .text li{float: left;}
</style>
